import React from "react";
import earnOverlaySec from "../../../assets/images/earn-overlay-sec-updated.png";



const styles = {
  earn: `relative w-full h-fit flex flex-col items-center justify-center bg-[#fff] lg:p-[1.25rem]`,
  earnContainer: `w-full lg:max-w-[75rem] h-fit lg:h-[39.7rem] lg:bg-[url('./assets/images/earn-bg.png')] bg-contain bg-center bg-no-repeat flex items-center pl-[1.25rem] py-[1.25rem] md:pl-[6.25rem] overflow-hidden`,
  leftCtn: `w-1/2 h-fit flex flex-col gap-[1.5rem]`,
  titlePrimary: `text-[1.25rem] lg:text-[2.25rem] font-extrabold lg:leading-[2.7rem] text-[#211E1E]`,
  rightCtn: `w-1/2 h-fit flex flex-col gap-[1.5rem] items-end justify-center`,
  rightCtnImage: `block lg:hidden w-full h-auto`,
  heading: `text-[0.87rem] lg:text-  text-[#2B8AC8] font-semibold tracking-[0.1rem] uppercase`,
  bio: `w-full text-[0.62rem] lg:text-[1.12rem] font-medium lg:leading-[1.57rem] text-[#82849E] z-30`,
  rectangleBorder: `w-full absolute bottom-0 `,
  borderImg: `w-full h-full`,
};

const Earn = () => {
  return (
    <div className={styles.earn}>
      <div className={styles.earnContainer}>
        <div className={styles.leftCtn}>
        <div className={styles.titlePrimary}>
            Earn TradFi Rates <br /> Seamlessly with USDO
          </div>
          <div className={styles.bio}>
            On-ramping into crypto with USDO enables you to earn TradFi rates
            irrespective of what you do with USDO. You can hold USDO, sell it
            for BTC, or put it into a lending pool - you always keep earning the
            net yield as the asset backing USDO remains yours, and with that,
            also the yield accruing to it.
          </div>
        </div>
        <div className={styles.rightCtn}>
        <img
            src={earnOverlaySec}
            alt="earnOverlaySec"
            className={styles.rightCtnImage}
          />

        </div>

      </div>
      {/* <div className={styles.rectangleBorder}>
      <img src={RectangleWhite} alt="RectangleWhite" className={styles.borderImg}/>
      </div> */}
    </div>
  );
};

export default Earn;

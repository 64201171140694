import React, { useState } from "react";
import { motion} from "framer-motion";
import dropdownArrow from "../../../assets/icons/down-arrow.svg";

const styles = {
  about: `relative w-full min-h-[46rem] h-fit bg-[#F6F8FA] lg:pt-[3rem] lg:grid lg:grid-cols-2 overflow-hidden`,
  leftCtn: `w-full h-fit p-[1.25rem] lg:pl-[7.5rem] `,
  rightCtn: `w-full h-fit p-[1.25rem] lg:px-[7.5rem] lg:pt-[1.5rem] flex flex-col gap-[1.5rem] mb-[8rem]`,
  heading: `text-[0.62rem] lg:text-base text-[#2B8AC8] font-semibold tracking-[0.1rem] uppercase`,
  titlePrimary: `text-base lg:text-[2.25rem] font-extrabold lg:leading-[2.7rem] text-[#211E1E] mt-[0.5rem] lg:mt-[2rem] z-20`,
  titleSecondary: `text-base lg:text-[2.25rem] font-extrabold lg:leading-[2.7rem] about-heading-gradient z-20`,
  bio: `w-full text-[0.62rem] lg:text-[1.12rem] font-medium lg:leading-[1.57rem] text-[#82849E] mt-[0.8rem] lg:mt-[1.5rem]`,
  calendarContainer: `relative w-full h-fit flex flex-col gap-[1.5rem] rounded-[0.75rem] p-[1.5rem]`,
  yearTxt: `text-[0.81rem] lg:text-[1.125rem] font-bold text-[#110E2E] leading-[1.5rem] text-opacity-50`,
  yearTxtActive: `text-[0.81rem] lg:text-[1.125rem] font-bold text-[#110E2E] leading-[1.5rem]`,
  monthsCtn: `grid grid-cols-6 gap-[1rem]`,
  monthTxt: `text-[0.62rem] lg:text-base font-bold text-[#2B8AC8] leading-[1.5rem] tracking-[0.1rem] uppercase`,
  dropdownIcon: `absolute top-4 lg:top-7 right-4 lg:right-7 cursor-pointer `,
  dropdownIconToggled: `absolute top-4 lg:top-7 right-4 lg:right-7 rotate-180 cursor-pointer`,
  ctaContainer: `relative w-full lg:w-11/12 px-[1.25rem] mx-auto z-40`,
  ctaImage: `w-full h-full`,
  ctaCtn: `absolute bottom-0 w-full h-full flex items-center justify-between pl-[1.25rem] pr-[3.25rem] lg:px-[4.75rem]`,
  ctaTitle: `text-[0.62rem] lg:text-[0.87rem] lg:text-[1.25rem] lg:text-[3rem] font-extrabold text-[#FFF] lg:leading-[3.3rem] z-50`,
  primaryBtn: `w-fit h-fit text-[0.5rem] lg:text-[0.75rem] lg:text-base bg-[#110E2E] rounded-[0.25rem] lg:rounded-[0.75rem] p-[0.25rem] lg:px-[1.25rem] lg:px-[2.5rem] lg:py-[0.88rem]  font-semibold text-[#fff] cursor-pointer z-50`,
  rectangleBorder: `w-full absolute bottom-0`,
  borderImg: `w-full h-full`,
};

const CalendarItem = () => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(0);

  const handleSelect = (i: number) => {
    if (i === selectedIndex) {
      setSelectedIndex(null);
      return;
    }
    setSelectedIndex(i);
  };

  const years = [
    {
      key: `year1`,
      year: "2024",
      months: [
        {
          key: "month11",
          name: "jan",
        },
        {
          key: "month12",
          name: "feb",
        },
        {
          key: "month13",
          name: "mar",
        },
        {
          key: "month14",
          name: "apr",
        },
        {
          key: "month15",
          name: "may",
        },
        {
          key: "month16",
          name: "jun",
        },
        {
          key: "month17",
          name: "jul",
        },
        {
          key: "month18",
          name: "aug",
        },
        {
          key: "month19",
          name: "sep",
        },
        {
          key: "month110",
          name: "oct",
        },
        {
          key: "month111",
          name: "nov",
        },
        {
          key: "month112",
          name: "dec",
        },
      ],
    },
    {
      key: `year2`,
      year: "2023",
      months: [
        {
          key: "month21",
          name: "jan",
        },
        {
          key: "month22",
          name: "feb",
        },
        {
          key: "month23",
          name: "mar",
        },
        {
          key: "month24",
          name: "apr",
        },
        {
          key: "month25",
          name: "may",
        },
        {
          key: "month26",
          name: "jun",
        },
        {
          key: "month27",
          name: "jul",
        },
        {
          key: "month28",
          name: "aug",
        },
        {
          key: "month29",
          name: "sep",
        },
        {
          key: "month210",
          name: "oct",
        },
        {
          key: "month211",
          name: "nov",
        },
        {
          key: "month212",
          name: "dec",
        },
      ],
    },
    {
      key: `year3`,
      year: "2022",
      months: [
        {
          key: "month31",
          name: "jan",
        },
        {
          key: "month32",
          name: "feb",
        },
        {
          key: "month33",
          name: "mar",
        },
        {
          key: "month34",
          name: "apr",
        },
        {
          key: "month35",
          name: "may",
        },
        {
          key: "month36",
          name: "jun",
        },
        {
          key: "month37",
          name: "jul",
        },
        {
          key: "month38",
          name: "aug",
        },
        {
          key: "month39",
          name: "sep",
        },
        {
          key: "month310",
          name: "oct",
        },
        {
          key: "month311",
          name: "nov",
        },
        {
          key: "month312",
          name: "dec",
        },
      ],
    },
    {
      key: `year4`,
      year: "2021",
      months: [
        {
          key: "month41",
          name: "jan",
        },
        {
          key: "month42",
          name: "feb",
        },
        {
          key: "month43",
          name: "mar",
        },
        {
          key: "month44",
          name: "apr",
        },
        {
          key: "month45",
          name: "may",
        },
        {
          key: "month46",
          name: "jun",
        },
        {
          key: "month47",
          name: "jul",
        },
        {
          key: "month48",
          name: "aug",
        },
        {
          key: "month49",
          name: "sep",
        },
        {
          key: "month410",
          name: "oct",
        },
        {
          key: "month411",
          name: "nov",
        },
        {
          key: "month412",
          name: "dec",
        },
      ],
    },
  ];

  const fadeInVariants = {
    hidden: { opacity: 0, height: 0, zIndex: -1 },
    visible: {
      opacity: 1,
      height: "fit-content",
      zIndex: 0,
      transition: { duration: 0.3 },
    },
  };

  return (
    <>
     {years.map((el, i) => (
          <div className={`${styles.calendarContainer} ${selectedIndex === i && 'bg-[#FFF] '}`} key={el.key}>
            <div className={styles.yearTxt}>{el.year}</div>
            <motion.div
              className={styles.monthsCtn}
              initial="hidden"
              animate={selectedIndex === i ? "visible" : "hidden"}
              variants={fadeInVariants}
            >
              {el.months.map((el, i) => (
                <div className={styles.monthTxt} key={el.key}>
                  {el.name}
                </div>
              ))}
            </motion.div>
            <img
              src={dropdownArrow}
              alt="dropdownArrow"
              className={
                selectedIndex === i
                  ? styles.dropdownIconToggled
                  : styles.dropdownIcon
              }
              onClick={() => handleSelect(i)}
            />
          </div>
        ))}
    </>
  );
};

export default CalendarItem
import { useEffect } from 'react';
import Footer from '../../components/landingpage/Footer';
import NavbarLanding from '../../components/navbar/NavbarLanding';
import HeroContactUs from './components/HeroContactUs';

import { useLocation } from 'react-router-dom';
const styles = {
    appWrapper: `w-full h-fit overflow-x-hidden`,
  };
  

const ContactUs = () => {

  
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

    //scroll to section
    const scrollToSection = (sectionId: string) => {
        if (typeof document !== "undefined") {
          const section = document.getElementById(sectionId);
          if (section) {
            section.scrollIntoView({ behavior: "smooth" });
          }
        }
      };
  
    return (
      <div className={styles.appWrapper}>
        <NavbarLanding scrollToSection={scrollToSection} />
        <HeroContactUs/>
        <Footer scrollToSection={scrollToSection}/>
      </div>
    )
}

export default ContactUs
import React from "react";

import voteLogo from "../../assets/icons/vote.svg";
import moneyLogo from "../../assets/icons/money.svg";
import museumLogo from "../../assets/icons/museum.svg";
import cloudLogo from "../../assets/icons/cloud-computing.svg";
import RectangleGrey from "../../assets/images/RectangleGrey.svg";

const styles = {
  eczodex: `relative w-full h-fit bg-[#fff] flex flex-col lg:pt-[3rem] overflow-hidden`,
  heading: `pl-[1.25rem] lg:pl-[7.5rem] text-[0.62rem] lg:text-base text-[#2B8AC8] font-semibold tracking-[0.1rem] uppercase`,
  titlePrimary: `w-5/6 md:w-4/6 pl-[1.25rem] lg:pl-[7.5rem] text-base lg:text-[2.25rem] font-extrabold lg:leading-[2.7rem] text-[#211E1E] mt-[0.5rem] lg:mt-[2rem] z-20`,
  cardsContainer: `w-full px-[1.25rem] lg:px-[7.5rem] mt-[0.8rem] lg:mt-[3.5rem] flex max-md:flex-col items-start lg:justify-between gap-y-[2rem] gap-x-[2.5rem] mb-[4rem] lg:mb-[8rem] xl:mb-[16rem]`,
  cardItem: `flex-1 h-fit flex flex-col gap-y-[0.93rem] lg:gap-y-[1.5rem]`,  
  cardLogo: `w-[2.5rem] h-[2.5rem]`,
  cardTitle: `font-extrabold text-[0.93rem] lg:text-[1.12rem] text-[#110E2E] lg:leading-[1.35rem]`,
  cardBio: `font-medium text-[0.75rem] lg:text-[1.12rem] text-[#82849E] lg:leading-[1.57rem]`,
  primaryBtn: `w-fit ml-[1.25rem] lg:ml-[7.5rem] mt-[3.75rem] lg:mt-[5.75rem] mb-[4rem] lg:mb-[8rem] px-[1.25rem] lg:px-[2.5rem] py-[0.62rem] lg:py-[1.25rem] bg-[#110E2E] rounded-[0.75rem] text-[#fff] text-[0.87rem] lg:text-base font-semibold`,
  rectangleBorder: `w-full absolute bottom-0`,
  borderImg: `w-full h-full`,
};

const Eczodex = () => {
  return (
    <div className={styles.eczodex}>
      <div className={styles.heading}>Why Eczodex?</div>

      <div className={styles.titlePrimary}>
        A secure digital asset issuance platform offering
        regulated parties access to new markets
      </div>
      <div className={styles.cardsContainer}>
        <div className={styles.cardItem}>
          <img src={voteLogo} alt="voteLogo" className={styles.cardLogo} />
          <div className={styles.cardTitle}>Seamless Integration</div>
          <div className={styles.cardBio}>
            Our platform integrates traditional capital market infrastructure
            with DLT technology, enabling collateralisation of RWAs without the
            need to implement complex regulatory rules on-chain. Our approach
            simplifies on-chain smart contract business logic and mitigates
            smart contract exploit risks while enhancing our overall platform
            security architecture.
          </div>
        </div>
        <div className={styles.cardItem}>
          <img src={moneyLogo} alt="moneyLogo" className={styles.cardLogo} />
          <div className={styles.cardTitle}>New Products</div>
          <div className={styles.cardBio}>
            In leveraging our technology we can help clients offer new financial
            solutions beyond the limitations of existing financial products.
          </div>
        </div>
        <div className={styles.cardItem}>
          <img src={museumLogo} alt="museumLogo" className={styles.cardLogo} />
          <div className={styles.cardTitle}>Regulatory Compliance</div>
          <div className={styles.cardBio}>
            Eczodex acts strictly as the tech platform provider to regulated
            financial institutions such as MSBs, VASPS, Banks and Broker-dealer
            platforms. Our platform connects traditional institutional partners
            with regulated market leaders in the digital asset ecosystem.
          </div>
        </div>
      </div>
      {/* <div className={styles.primaryBtn}>How USDO Works</div> */}
      <div className={styles.rectangleBorder}>
        <img
          src={RectangleGrey}
          alt="RectangleGrey"
          className={styles.borderImg}
        />
      </div>
    </div>
  );
};

export default Eczodex;


import RectangleWhite from "../../assets/images/RectangleWhite.svg";

import serviceBgLeft from "../../assets/images/serviceBgLeft.png";
import serviceBgCenter from "../../assets/images/serviceBgCenter.png";
import serviceBgRight from "../../assets/images/serviceBgCenterRight.png";
import CtaItem from "./CtaItem";

const styles = {
  services: `relative w-full h-fit bg-[#F6F8FA] flex flex-col lg:pt-[3rem] overflow-hidden`,
  heading: `pl-[1.25rem] lg:pl-[7.5rem] text-[0.62rem] lg:text-base text-[#2B8AC8] font-semibold tracking-[0.1rem] uppercase`,
  titlePrimary: `pl-[1.25rem] lg:pl-[7.5rem] text-base lg:text-[2.25rem] font-extrabold lg:leading-[2.7rem] text-[#211E1E] mt-[0.5rem] lg:mt-[2rem] z-20`,
  cardsContainer: `w-full px-[1.25rem] lg:px-[7.5rem] mt-[0.8rem] lg:mt-[3.5rem] flex max-md:flex-col  items-start lg:justify-between gap-y-[2rem] gap-x-[2.5rem] mb-[4rem] lg:mb-[8rem] xl:mb-[16rem]`,
  cardItem: `flex-1 min-h-[10rem] lg:min-h-[21.8rem] p-[1rem] lg:p-[2rem] bg-[#fff] rounded-[1rem] flex flex-col justify-between gap-y-[0.93rem] lg:gap-y-[1.5rem] `,
  cardTitle: `font-extrabold text-base lg:text-[2rem] text-[#110E2E] lg:leading-[2.2rem]`,
  cardBio: `font-medium text-[0.75rem] lg:text-[1.12rem] text-[#82849E] lg:leading-[1.57rem]`,
  primaryBtn: `w-fit ml-[1.25rem] lg:ml-[7.5rem] mt-[3.75rem] lg:mt-[5.75rem] mb-[4rem] lg:mb-[8rem] px-[1.25rem] lg:px-[2.5rem] py-[0.62rem] lg:py-[1.25rem] bg-[#110E2E] rounded-[0.75rem] text-[#fff] text-[0.87rem] lg:text-base font-semibold`,
  rectangleBorder: `w-full absolute bottom-0`,
  borderImg: `w-full h-full`,
};

const Services = () => {
  return (
    <div className={styles.services}>
      <div className={styles.heading}>services</div>

      <div className={styles.cardsContainer}>
      <div className={`${styles.cardItem} bg-[url('./assets/images/serviceBgLeft.png')] bg-cover bg-bottom bg-no-repeat`}>
          <div className={styles.cardTitle}>Product Design</div>
          <div className={styles.cardBio}>
            We help you create innovative digital asset products that align with
            your business goals.
          </div>
        </div>
        <div className={`${styles.cardItem} bg-[url('./assets/images/serviceBgCenter.png')] bg-cover bg-bottom bg-no-repeat`}>
          <div className={styles.cardTitle}>Product Build</div>
          <div className={styles.cardBio}>
            Our team helps build your scalable, compliant digital products,
            integrating the latest technologies to bring your ideas to life
            efficiently.
          </div>
        </div>
        <div className={`${styles.cardItem} bg-[url('./assets/images/serviceBgCenterRight.png')] bg-cover bg-bottom bg-no-repeat`}>
          <div className={styles.cardTitle}>Consultancy</div>
          <div className={styles.cardBio}>
            We offer expert guidance on product development, regulatory
            compliance, and go to market strategies to help your business
            succeed.
          </div>
        </div>
      </div>

      <CtaItem/>
      <div className={styles.rectangleBorder}>
        <img
          src={RectangleWhite}
          alt="RectangleWhite"
          className={styles.borderImg}
        />
      </div>
    </div>
  );
};

export default Services;

import React, { useEffect } from "react";
import NavbarLanding from "../../components/navbar/NavbarLanding";

import Footer from "../../components/landingpage/Footer"
import HeroProducts from "./components/HeroProducts";
import Steps from "./components/Steps";
import Earn from "./components/Earn";
import Features from "./components/Features";
import { useLocation } from 'react-router-dom';


const styles = {
  appWrapper: `w-full h-fit overflow-x-hidden`,
};

const Products = () => {

    
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  //scroll to section
  const scrollToSection = (sectionId: string) => {
    if (typeof document !== "undefined") {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <div className={styles.appWrapper}>
      <NavbarLanding scrollToSection={scrollToSection} />
      <HeroProducts />
      <Steps/>
      <Earn/>
      <Features/>
      <Footer scrollToSection={scrollToSection}/>
    </div>
  );
};

export default Products;


import eczodexLogo from "../../assets/icons/eczodex-main-logo.svg";

const styles = {
  wrapper: `relative w-full h-fit flex flex-col`,
  navbar: `w-full h-fit px-[1.25rem] py-[1.63rem] flex absolute top-0 justify-center items-center z-50`,
  logoCtn: `flex`,
};

interface IProps {
  scrollToSection: (urlFragment: string) => void;
}

const NavbarLanding = ({ scrollToSection }: IProps) => {

  return (
    <div className={styles.wrapper}>
      <div className={styles.navbar}>
        <a href="/" className={styles.logoCtn}>
          <img src={eczodexLogo} alt="main-logo" width={150} height={36} />
        </a>
      </div>
    </div>
  );
};

export default NavbarLanding;

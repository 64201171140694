import React from "react";
import RectangleColored from "../../assets/images/RectangleColored.png";
import { eczodexAppUrl } from "../../lib/constants";

const styles = {
  ctaContainer: `relative w-full lg:w-11/12 px-[1.25rem] mx-auto z-40 overflow-hidden hidden md:block`,
  ctaImage: `w-full h-full `,
  ctaCtn: `absolute bottom-0 w-full h-full flex items-center justify-center pl-[1.25rem] pr-[3.25rem] lg:px-[4.75rem]`,
  ctaTitle: `text-[1.37rem] lg:text-[3rem] font-extrabold text-[#FFF] lg:leading-[3.3rem] z-50 cursor-pointer`,
  primaryBtn: `bg-[#110E2E] rounded-[0.75rem]  px-[2.5rem] py-[0.88rem] text-[0.87rem] md:text-base font-semibold text-[#fff] cursor-pointer`,
  ctaContainerSec: `w-full h-[16rem] flex flex-col gap-[2rem] md:hidden items-center justify-center bg-[url('./assets/images/cta-bg.png')] bg-cover bg-center bg-no-repeat`,
};

const CtaItem = () => {
  return (
    <>
      <div className={styles.ctaContainer}>
        <div className={styles.ctaCtn}>
        <a href="mailto:enquiries@eczodex.com" className={styles.ctaTitle}>Contact Us</a>

        </div>
        <img
          src={RectangleColored}
          alt="RectangleColored"
          className={styles.ctaImage}
        />
      </div>
      <div className={styles.ctaContainerSec}>
      <a href="mailto:enquiries@eczodex.com" className={styles.ctaTitle}>Contact Us</a>
      </div>
    </>
  );
};

export default CtaItem;

import NavbarLanding from "../../components/navbar/NavbarLanding";
import React, { useEffect } from "react";

import Footer from "../../components/landingpage/Footer";
import HeroTransparency from "./components/HeroTransparency";
import Analytics from "./components/Analytics";
import { useLocation } from 'react-router-dom';


const styles = {
  appWrapper: `w-full h-fit overflow-x-hidden`,
};

const Transparency = () => {
    
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  //scroll to section
  const scrollToSection = (sectionId: string) => {
    if (typeof document !== "undefined") {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <div className={styles.appWrapper}>
      <NavbarLanding scrollToSection={scrollToSection} />
      <HeroTransparency />
      <Analytics/>
      <Footer scrollToSection={scrollToSection}/>
    </div>
  );
};

export default Transparency
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import eczodexLogo from "../../assets/icons/eczodex-main-logo.svg";
import emailLogo from "../../assets/icons/email.svg";
import instaLogo from "../../assets/icons/ig.svg";
import xLogo from "../../assets/icons/x.svg";
import { eczodexAppUrl } from "../../lib/constants";

const styles = {
  footer: `w-full h-fit min-h-[18.4rem] bg-[#FFF] gap-y-[2.5rem] flex items-center justify-center p-[1.25rem] lg:px-[10rem] lg:py-[3.8rem]`,
  logoCtn: `w-[9.3rem] h-[2.3rem]`,
  copyrightCtn: `text-[#82849E] text-base lg:text-[1.125rem] font-normal`,
  copyrightCtnSec: `text-[#82849E] text-base lg:text-[1.125rem] font-normal`,
};

interface IProps {
  scrollToSection: (urlFragment: string) => void;
}

const Footer = ({ scrollToSection }: IProps) => {
  const location = useLocation();
  const { pathname } = location;
  const [currentRoute, setCurrentRoute] = useState("");

  useEffect(() => {
    if (pathname === "/") {
      setCurrentRoute("landing");
    } else if (pathname === "/contact-us") {
      setCurrentRoute("contact-us");
    }
  }, [pathname]);

  return (
    <div className={styles.footer}>
      <div className="w-fit flex flex-col items-center justify-center gap-[1rem] lg:gap-[2.5rem]">
        <img
          src={eczodexLogo}
          alt="main-logo"
          width={150}
          height={36}
          className={styles.logoCtn}
        />
        <div className={styles.copyrightCtn}>Ⓒ All rights reserved 2024</div>
      </div>
    </div>
  );
};

export default Footer;

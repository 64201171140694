import React, { useState } from "react";

import RectangleWhite from "../../../assets/images/RectangleWhite.svg";
import SelectDropdownThree from "../../../components/SelectDropdownThree";
import { toast } from "react-toastify";
import axios from "axios";
import { azureApiUrl, RE_EMAIL } from "../../../lib/constants";
import EczodexLoader from "../../../components/EczodexLoader";

const styles = {
  hero: `relative w-full h-fit min-h-[65rem] bg-[url('./assets/images/HeroBg.png')] bg-cover bg-center max-lg:flex-col flex gap-[1rem] lg:gap-[2.5rem] items-center`,
  leftCtn: `w-full h-fit px-[1.25rem] lg:pl-[7.5rem] max-lg:pt-[6rem] flex-col flex gap-[1.5rem] `,
  heroHeading: `text-[1.5rem] lg:text-[3.75rem] font-extrabold lg:leading-[4.18rem] text-center hero-heading-gradient p-5 mb-[1.75rem] `,
  heroBio: `text-[0.87rem] lg:text-[1.56rem] font-normal text-center hero-heading-gradient p-5 mb-[7.38rem] `,
  rightCtn: `w-full h-fit px-[1.25rem] lg:pr-[7.5rem] mb-[2rem] lg:mb-[6rem]`,
  formContainer: `p-[1.25rem] lg:p-[2.5rem] flex flex-col gap-[1.5rem] bg-[#FFF] rounded-[1rem]`,
  topCtn: `flex flex-wrap gap-[1.5rem]`,
  bottomCtn: `flex flex-col gap-[1.5rem]`,
  inputContainer: `w-full flex flex-col gap-[0.38rem]`,
  inputTitle: `text-[0.87rem] lg:text-base text-[#110E2E] font-medium`,
  asterisk: `text-[0.87rem] lg:text-base text-[#E82127] font-medium ml-[0.25rem]`,
  inputWrapper: `w-full p-[0.75rem] rounded-[0.25rem] border border-[#D8DDE7]`,
  inputWrapperSec: `w-full h-[6rem] p-[0.75rem] rounded-[0.25rem] border border-[#D8DDE7]`,
  inputBox: `w-full h-full outline-none box-border resize-none`,
  pimaryBtn: `px-[1.25rem] lg:px-[2.5rem] py-[0.88rem] text-center font-semibold text-[0.87rem] lg:text-base text-[#FFF] bg-[#110E2E] rounded-[0.75rem]`,
  rectangleBorder: `w-full absolute bottom-0`,
  borderImg: `w-full h-full`,
  loader: `w-fit mx-auto`,
};

interface FormData {
  email: string;
  subject: string;
  title: string;
  details: string;
}


const previewItem = {
  email: "",
  subject: "",
  title: "",
  details: "",
}

const HeroContactUs = () => {
  const [selectedIndexSubject, setSelectedIndexSubject] = useState<
    number | null
  >(null);
  const onChangeSelectSubject = (value: number) =>
    setSelectedIndexSubject(value);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState<FormData>(previewItem);

  const items = ["Option 1", "Option 2"];

  // Event handler for input changes
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Event handler for form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const isValidEmail = RE_EMAIL.test(formData.email);
    console.log(formData.email);
    if (formData.email.trim() === "" || !isValidEmail) {
      toast.error("Please enter a valid email address.");
      return;
    }

    if (formData.subject.trim() === "") {
      toast.error("Please enter subject.");
      return;
    }

    const isValidSubject =
      formData.subject.length >= 2 && formData.subject.length <= 50;
    const isValidTitle =
      formData.title.length >= 2 && formData.title.length <= 50;
    const isValidDetails =
      formData.title.length >= 2 && formData.title.length <= 150;

    if (!isValidSubject) {
      toast.error("Subject must be between 2 and 50 characters");
      return;
    }

    if (formData.title.trim() === "") {
      toast.error("Please enter title.");
      return;
    }

    if (!isValidTitle) {
      toast.error("Title must be between 2 and 50 characters.");
      return;
    }

    if (formData.details.trim() === "") {
      toast.error("Please enter details.");
      return;
    }

    if (!isValidDetails) {
      toast.error("Title must be between 2 and 150 characters.");
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios({
        method: "post",
        url: `${azureApiUrl}/Misc/SubmitEmail`,
        data: formData,
      });

      if (response.status === 200) {
        toast.success("Submitted Succesfully!");
      }
      setFormData(previewItem);
      setIsLoading(false);
    } catch (error: any) {
      if (error.response) {
        toast.error(error?.response?.data);
      }
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.hero}>
      <div className={styles.leftCtn}>
        <div className={styles.heroHeading}>
          We can&rsquo;t wait to <br />
          hear from you
        </div>
        {/* <div className={styles.heroBio}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce dictum,
          mauris ac dapibus egestas
        </div> */}
      </div>
      <div className={styles.rightCtn}>
        <form className={styles.formContainer} onSubmit={handleSubmit}>
          <div className={styles.topCtn}>
            <div className={styles.inputContainer}>
              <div className={styles.inputTitle}>
                Email<span className={styles.asterisk}>*</span>
              </div>
              <div className={styles.inputWrapper}>
                <input
                  name="email"
                  type="email"
                  value={formData.email}
                  className={styles.inputBox}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className={styles.inputContainer}>
              <div className={styles.inputTitle}>
                Subject<span className={styles.asterisk}>*</span>
              </div>
              <div className={styles.inputWrapper}>
                <input
                  name="subject"
                  type="text"
                  value={formData.subject}
                  className={styles.inputBox}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
              </div>
            </div>
          </div>
          <div className={styles.bottomCtn}>
            <div className={styles.inputContainer}>
              <div className={styles.inputTitle}>Title<span className={styles.asterisk}>*</span></div>
              <div className={styles.inputWrapper}>
                <input
                  name="title"
                  type="text"
                  value={formData.title}
                  className={styles.inputBox}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className={styles.inputContainer}>
              <div className={styles.inputTitle}>
                Details<span className={styles.asterisk}>*</span>
              </div>
              <div className={styles.inputWrapperSec}>
                <textarea
                  name="details"
                  value={formData.details}
                  className={styles.inputBox}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
              </div>
            </div>
            {isLoading ? (
              <div className={styles.loader}>
                <EczodexLoader />
              </div>
            ) : (
              <button type="submit" className={styles.pimaryBtn}>
                Submit
              </button>
            )}
          </div>
        </form>
      </div>

      <div className={styles.rectangleBorder}>
        <img
          src={RectangleWhite}
          alt="RectangleWhite"
          className={styles.borderImg}
        />
      </div>
    </div>
  );
};

export default HeroContactUs;

import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import Products from "./pages/products/Products";
import ContactUs from "./pages/contact-us/ContactUs";
import Transparency from "./pages/transparency/Transparency";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/products" element={<Products/>}/>
      <Route path="/about" element={<About/>}/>
      <Route path="/contact-us" element={<ContactUs/>}/>
      <Route path="/transparency" element={<Transparency/>}/>
    </Routes>
  )
}

export default App;

import React from "react";

import eczodexLogo from "../../../assets/icons/eczodex-step.png";
import museumLogo from "../../../assets/icons/museum-step.png";
import cashLogo from "../../../assets/icons/cash-step.png";
import stepArrowOne from "../../../assets/icons/step1-2.png";
import stepArrowOneSec from "../../../assets/icons/step1-2-sec.png";
import stepArrowTwo from "../../../assets/icons/step2-3.png";
import stepArrowTwoSec from "../../../assets/icons/step2-3-sec.png";


const styles = {
  steps: `w-full h-fit bg-[#fff]`,
  stepsContainer: `w-full px-[1.25rem] lg:px-[4.5rem] xl:px-[7.5rem] pt-[2rem] pb-[0.94rem] hidden lg:grid lg:grid-cols-3 justify-center bg-[url('./assets/images/stepsbg.png')] bg-contain bg-no-repeat bg-top`,
  stepCard: `relative w-full h-fit px-[2rem] py-[3.25rem] lg:py-[6.25rem] flex  flex-col gap-[1.5rem]`,
  stepCardSec: `relative w-full h-fit px-[2rem] py-[3.25rem] lg:py-[6.25rem] step-card-border-gradient flex  flex-col gap-[1.5rem]`,
  title: `text-[0.87rem] xl:text-base text-center font-bold text-[#2B8AC8] tracking-[0.1rem] uppercase`,
  bio: `text-base xl:text-[1.25rem] text-center font-bold text-[#110E2E] leading-[1.5rem]`,
  bioSec: `text-base xl:text-[1.25rem] text-left font-bold text-[#110E2E] leading-[1.5rem]`,
  stepIconImg: `w-[4rem] xl:w-fit absolute left-1/2 -top-[14%] transform -translate-x-1/2 z-10`,
  stepIconImgSec: `w-[4rem] xl:w-fit absolute left-1/2 -top-[20%] transform -translate-x-1/2 z-10`,
  stepIconImgTer: `w-[4rem] xl:w-fit absolute left-1/2 -top-[23%] transform -translate-x-1/2 z-10`,
  stepArrowImg: `w-[7rem] xl:w-fit absolute -top-[10%] lg:-right-[16%] xl:-right-[21%] 2xl:-right-[16%] hidden lg:block z-20`,
  stepArrowImgSec: `w-[7rem] xl:w-fit absolute -top-[14%] lg:-right-[19%] xl:-right-[21%] 2xl:-right-[16%] hidden lg:block z-20`,
  stepArrowAlt: `w-[7rem] mx-auto block lg:hidden mb-[1.5rem]`,
  stepsContainerSec: `w-full h-fit px-[1.25rem] lg:px-[4.5rem] xl:px-[7.5rem] pt-[2rem] pb-[0.94rem] lg:hidden flex gap-[2rem]`,
  stepLeftCtn: `w-1/5 md:w-2/5 h-auto flex flex-col items-center justify-between bg-[url('./assets/images/StepsBgSec.png')] bg-contain bg-no-repeat bg-center`,
  stepRightCtn: `w-4/5 md:w-3/5 flex flex-col items-center justify-between gap-[2rem]`,
  stepCardItem: `w-full  flex flex-col items-start justify-between gap-[1.5rem]`,
  stepCardItemCtn: `w-full md:w-4/5  flex flex-col items-start justify-between gap-[0.75rem]`,
  stepNavImg: `w-[8rem]`,
};

const Steps = () => {

  return (
    <div className={styles.steps}>
      <div className={styles.stepsContainer}>
        <div className={styles.stepCard}>
          <img
            src={eczodexLogo}
            alt="eczodexLogo"
            className={styles.stepIconImg}
          />
          <img
            src={stepArrowOne}
            alt="stepArrowTwo"
            className={styles.stepArrowImg}
          />
          <div className={styles.title}>Step 1</div>
          <div className={styles.bio}>
            User Funds are transferred to a segregated bank account and held as
            collateral
          </div>

        </div>
        <div className={styles.stepCardSec}>
          <img
            src={museumLogo}
            alt="museumLogo"
            className={styles.stepIconImgSec}
          />
          <img
            src={stepArrowTwo}
            alt="stepArrowTwo"
            className={styles.stepArrowImgSec}
          />
          <div className={styles.title}>Step 2</div>
          <div className={styles.bio}>USDO Minted</div>

        </div>
        <div className={styles.stepCard}>
          <img
            src={cashLogo}
            alt="cashLogo"
            className={styles.stepIconImgTer}
          />
          <div className={styles.title}>Step 3</div>
          <div className={styles.bio}>
            Yield on Minter Owned Collateral is paid out on request
          </div>
        </div>
      </div>
      <div className={styles.stepsContainerSec}>
        <div className={styles.stepLeftCtn}>
          <img src={eczodexLogo} alt="eczodexLogo" width={64} height={64} />
          <img src={museumLogo} alt="museumLogo" width={64} height={64} />
          <img src={cashLogo} alt="cashLogo" width={64} height={64} />
        </div>
        <div className={styles.stepRightCtn}>
          <div className={styles.stepCardItem}>
            <div className={styles.stepCardItemCtn}>
              <div className={styles.title}>Step 1</div>
              <div className={styles.bioSec}>
                User Funds are transferred to a segregated bank account and held
                as collateral
              </div>
            </div>
            <img
              src={stepArrowOneSec}
              alt="stepArrowOneSec"
              className={styles.stepNavImg}
            />
          </div>
          <div className={styles.stepCardItem}>
            <div className={styles.stepCardItemCtn}>
              <div className={styles.title}>Step 2</div>
              <div className={styles.bioSec}>USDO Minted</div>
            </div>
            <img
              src={stepArrowTwoSec}
              alt="stepArrowTwoSec"
              className={styles.stepNavImg}
            />
          </div>
          <div className={styles.stepCardItem}>
            <div className={styles.stepCardItemCtn}>
              <div className={styles.title}>Step 3</div>
              <div className={styles.bioSec}>
                Yield on Minter Owned Collateral is paid out on request
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Steps;

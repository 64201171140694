import React from "react";

import eczodexLogo from "../../assets//icons/eczodex-logo-sec.svg";
import scrollLogo from "../../assets/icons/scroll-icon.svg";

import RectangleGrey from "../../assets/images/RectangleGrey.svg";

const styles = {
  hero: `relative w-full h-fit min-h-[45rem] lg:min-h-[65rem]  px-[1.25rem] bg-[url('./assets/images/HeroBg.png')] bg-cover bg-center flex flex-col items-center justify-center overflow-hidden`,
  assetCtn: `w-fit pl-[0.25rem] pr-[0.75rem] py-[0.25rem] mt-[4rem] gap-[0.62rem] flex items-center bg-[#fff] bg-opacity-15 rounded-[3.125rem] border border-white border-opacity-40 mb-[2.5rem]`,
  logoCtn: `w-fit flex items-center`,
  logoTxt: `text-base lg:text-[1.25rem] text-center text-[#fff] font-bold tracking-[0.125rem] uppercase`,
  heroHeading: `text-[1.5rem] lg:text-[3.75rem] font-extrabold lg:leading-[4.18rem] text-center hero-heading-gradient p-5 mb-[1.75rem] `,
  heroBio: `text-[0.87rem] lg:text-[1.56rem] font-normal text-center hero-heading-gradient p-5 mb-[7.38rem] `,
  scrollCtn: `flex flex-col gap-[1.06rem] items-center`,
  scrollIconCtn: `flex`,
  scrollTxt: `text-[0.75rem] font-medium text-[#fff]`,
  rectangleBorder: `w-full absolute bottom-0`,
  borderImg: `w-full h-full`,
};

const Hero = () => {
  return (
    <div className={styles.hero}>
      <div className={styles.heroHeading}>
        Embedded technology for <br />
        the new financial economy
      </div>
      <div className={styles.heroBio}>
      Connecting old and new markets to unleash <br />
      products fit for the new economy 
      </div>
      <div className={styles.scrollCtn}>
        <div className={styles.scrollIconCtn}>
          <img src={scrollLogo} alt="scroll-logo" width={23} height={33} />
        </div>
        <div className={styles.scrollTxt}>Scroll down for more details</div>
      </div>
      <div className={styles.rectangleBorder}>
        <img
          src={RectangleGrey}
          alt="RectangleGrey"
          className={styles.borderImg}
        />
      </div>
    </div>
  );
};

export default Hero;
